import { Component, EventEmitter, Input, Output, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { GenericComponent } from '../generic/generic.component';

@Component({
  selector: 'nsc-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuestionnaireComponent extends GenericComponent {
  @Input() form!: UntypedFormGroup;
  @Input() errors!: string[];
  @Input() formLoading!: boolean;
  @Output() calculateEvent = new EventEmitter<any>();
  @Output() resetEvent = new EventEmitter<void>();

  constructor() {
    super();
  }

  calculate(formData: any): void {
    this.calculateEvent.emit(formData);
  }

  reset(): void {
    this.resetEvent.emit();
  }
}
