import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { InfoModel } from './models/info.model';
import { FileModel } from './models/file.model';
import { PrefillModel } from './models/prefill.model';

import { PrefillService } from './services/prefill.service';
import { NetsheetService } from './services/netsheet.service';
import { GenericComponent } from './components/generic/generic.component';

import { version } from './../info.json';

import moment from 'moment';
import { ResetService } from './services/reset.service';

@Component({
  selector: 'nsc-netsheet-calculator',
  templateUrl: './netsheet-calculator.component.html',
  styleUrls: ['./netsheet-calculator.component.scss']
})
export class NetsheetCalculatorComponent extends GenericComponent implements OnInit {
  @Output() export = new EventEmitter<FileModel>();

  version = '';
  reportData: any;
  formLoading = false;
  errors: string[] = [];
  prefill: PrefillModel = {};

  form: UntypedFormGroup = this.formBuilder.group({
    customerInformation: this.formBuilder.group({
      partyFirstName: [''],
      partyLastName: [''],
      partyType: ['S']
    }),

    transactionInformation: this.formBuilder.group({
      attBrokerageFee: [''],
      attEstcoe: [''],
      attFirpta: [true],
      attFtb: [true],
      attHarpta: [true],
      attNewLoan: [false],
      attOwnersExemption: [true],
      attOwnersPolicySplit: ['Seller'],
      attPersonalResidence: [true],
      attReo: [false],
      attSalesPrice: [''],
      attSewerBill: [''],
      attShortSale: [false],
      attSubEscrow: [false],
      attSurveyAmendmentSplit: [false],
      attWaterBill: [''],

      buyerCreditAmount: [''],

      escrowBranch: [''],
      escrowCity: [''],
      escrowCounty: [''],
      escrowState: [''],

      hazardInsuranceAmount: [''],

      loanPrimaryAmount: [''],
      loanPrimaryInterestRate: [''],
      loanPrimaryOriginationFees: [''],

      mortgagePayoffCount: [''],

      propertyAddress: [''],
      propertyState: [''],
      propertyCounty: [''],
      propertyCity: new UntypedFormControl('', {
        updateOn: 'blur'
      }),
      propertyZip: [''],
      propertyIncorporatedArea: [false],

      warrantyPremiumAmount: ['']
    }),

    mortgages: this.formBuilder.group({
      mortgagePrimaryBalance: [''],
      mortgagePrimaryInterestRate: [''],
      mortgagePrimaryLienCloseDate: [''],
      mortgagePrimaryPrepayPenalty: [''],
      mortgageSecondaryBalance: [''],
      mortgageSecondaryInterestRate: [''],
      mortgageSecondaryPrepayPenalty: ['']
    }),

    salesCommissions: this.formBuilder.group({
      brokerCommissionListing: [''],
      brokerCommissionSelling: [''],
      brokerCommissionSplits: [false],
      brokerCommissionTaxListing: [false],
      brokerCommissionTaxSelling: [false],
      brokerCommissionTaxTotal: [false],
      brokerCommissionTierRate: [''],
      brokerCommissionTotal: ['']
    }),

    taxes: this.formBuilder.group({
      homeownersDuesAmount: [''],
      homeownersDuesDueDate: [''],
      homeownersDuesPaid: [true],
      homeownersDuesTerm: ['Monthly'],

      taxes: [''],
      taxesNextInstallment: [''],
      taxesPaid: [true],
      taxesPaidToDatePicker: [''],
      taxesPaidToDateDropdown: [''],
      taxesTerm: ['Semi-Annually']
    }),

    miscItems: this.formBuilder.array([
      this.formBuilder.group({
        amount: [''],
        description: [''],
        type: ['Debit']
      })
    ]),

    userInformation: this.formBuilder.group({
      userCompany: [''],
      userEmail: [''],
      userFirstName: [''],
      userLastName: [''],
      userPhone: ['']
    })
  });

  formValues: any = this.form.value;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private netsheetService: NetsheetService,
    private prefillService: PrefillService,
    private resetService: ResetService
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.netsheetService.getInformation().subscribe((info: InfoModel) => {
        this.version = 'v' + version + ' (Engine ' + info.version + ' | ' + info.env.toUpperCase() + ')';
      })
    );

    this.subscriptions.push(
      this.prefillService.getPrefill().subscribe((prefill: PrefillModel) => {
        this.prefill = prefill;
        this.patchPrefill();
      })
    );

    this.subscriptions.push(
      this.resetService.getShouldReset().subscribe((shouldReset) => {
        if (shouldReset) {
          this.resetForm();
          this.resetService.toggleReset(false);
        }
      })
    );
  }

  calculateForm(formData: any): void {
    this.errors = [];

    if (this.form.valid) {
      this.form.disable({ emitEvent: false });
      this.formLoading = true;

      const data = {
        ...formData.customerInformation,
        ...formData.transactionInformation,
        ...formData.mortgages,
        ...formData.salesCommissions,
        ...formData.taxes,
        ...formData.userInformation
      };
      data.miscItems = formData.miscItems;

      this.subscriptions.push(
        this.netsheetService.calculate(data).subscribe({
          next: (response: any) => {
            this.reportData = response;
            this.formLoading = false;
            this.form.enable({ emitEvent: false });
          },
          error: (error: any) => {
            this.reportData = undefined;

            if (error.error) {
              const err = error.error;

              if (err.messages && err.messages.length) {
                for (const message of err.messages) {
                  this.errors.push(message);
                }
              }
            }

            this.formLoading = false;
            this.form.enable({ emitEvent: false });
          }
        })
      );
    } else {
      this.errors.push('Please check all the fields marked with red!');
      this.form.markAllAsTouched();
    }
  }

  resetForm(): void {
    this.errors = [];
    this.form.reset(this.formValues);
    this.patchPrefill();
  }

  onExport(blob: Blob): void {
    const type = 'pdf';
    const currentDate = moment().format('MM-DD-YYYY');
    const partyType = this.form?.get('customerInformation.partyType')?.value;
    const name =
      (partyType === 'S' ? 'Seller' : partyType === 'B' ? 'Buyer' : 'Borrower') +
      '-Net-Sheet-' +
      currentDate +
      '.' +
      type;

    this.export.emit({ blob, name, type });
  }

  onBack(): void {
    this.reportData = undefined;
  }

  patchPrefill(): void {
    this.form?.get('transactionInformation')?.patchValue({
      propertyAddress: this.prefill.propertyAddress,
      propertyState: this.prefill.propertyState,
      propertyCounty: this.prefill.propertyCounty,
      propertyCity: this.prefill.propertyCity,
      propertyZip: this.prefill.propertyZip,
      escrowState: this.prefill.propertyState
    });

    this.form?.get('userInformation')?.patchValue({
      userCompany: this.prefill.userCompany,
      userEmail: this.prefill.userEmail,
      userFirstName: this.prefill.userFirstName,
      userLastName: this.prefill.userLastName,
      userPhone: this.prefill.userPhone
    });
  }
}
