import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { OrganizationEnum } from '../enums/organization.enum';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  organization: BehaviorSubject<OrganizationEnum>;

  constructor() {
    this.organization = new BehaviorSubject(OrganizationEnum.OLD_REPUBLIC_TITLE);
  }

  setOrganization(organization: OrganizationEnum): void {
    this.organization.next(organization);
  }

  getOrganization(): Observable<OrganizationEnum> {
    return this.organization.asObservable();
  }
}
