import { Injectable } from '@angular/core';

import { ThemeService } from './theme.service';
import { DeviceService } from './device.service';
import { PrefillService } from './prefill.service';
import { OrganizationService } from './organization.service';

import { PrefillModel } from '../models/prefill.model';
import { OrganizationEnum } from '../enums/organization.enum';
import { ResetService } from './reset.service';

@Injectable({
  providedIn: 'root'
})
export class NetsheetConfigService {
  constructor(
    private themeService: ThemeService,
    private deviceService: DeviceService,
    private prefillService: PrefillService,
    private resetService: ResetService,
    private organizationService: OrganizationService
  ) {}

  setDevice(device: 'mobile' | 'desktop'): void {
    this.deviceService.setDevice(device);
  }

  setColors(primary: string, accent: string): void {
    this.themeService.setColors({
      primary,
      accent
    });
  }

  setOrganization(organization: OrganizationEnum): void {
    this.organizationService.setOrganization(organization);
  }

  updatePrefill(prefill: PrefillModel): void {
    this.prefillService.updatePrefill(prefill);
  }

  reset(): void {
    this.resetService.reset();
  }
}
