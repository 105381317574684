import { Directive, HostListener, Injector, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NgControl } from '@angular/forms';
import { CurrencyPipe, PercentPipe } from '@angular/common';
import { round, toString } from 'lodash-es';

@Directive({
  selector: '[nscRateAmount]',
  providers: [CurrencyPipe, PercentPipe]
})
export class RateAmountDirective implements OnChanges, OnInit {
  @Input('nscRateAmount') type: '' | 'rate' | 'amount' | 'rateAmount' = '';

  private rawModelValue = '';

  constructor(
    private injector: Injector,
    private ngControl: NgControl
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.type) {
      this.rawModelValue = toString(this.ngControl?.control?.value);
      this.updateValues();
    }
  }

  ngOnInit(): void {
    const view: ControlValueAccessor | null = this.ngControl?.valueAccessor;

    if (view) {
      view.registerOnChange((value: string) => {
        this.rawModelValue = value;
      });
    }
  }

  @HostListener('blur') onBlur(): void {
    this.updateValues();
  }

  updateValues(): void {
    const model: AbstractControl | null = this.ngControl?.control;
    const view: ControlValueAccessor | null = this.ngControl?.valueAccessor;

    if (model && view) {
      const modelValue = this.getModelValue(this.rawModelValue);
      const viewValue = this.getViewValue(modelValue);

      if (this.type === 'rateAmount' && this.isRateValue()) {
        model.setValue(viewValue);
      } else {
        model.setValue(modelValue);
      }

      view.writeValue(viewValue);
    }
  }

  isRateValue(): boolean {
    const value = this.getModelValue(this.rawModelValue);
    let result = false;

    if (this.rawModelValue.includes('%') || (value && value < 20)) {
      result = true;
    }

    return result;
  }

  getModelValue(value: string): number | null {
    const pattern = /[^\d.-]/g;
    let result: number | null = parseFloat(value.replace(pattern, ''));

    if (isNaN(result)) {
      result = null;
    } else if (result < 0) {
      result = 0;
    } else {
      result = round(result, 2);
    }

    return result;
  }

  getViewValue(value: number | null): string | null {
    let result: string | null = null;

    if (value !== null) {
      switch (this.type) {
        case 'amount':
          result = this.getAmountViewValue(value);
          break;

        case 'rateAmount':
          if (this.isRateValue()) {
            result = this.getRateViewValue(value);
          } else {
            result = this.getAmountViewValue(value);
          }

          break;
        default:
          result = this.getRateViewValue(value);
          break;
      }
    }

    return result;
  }

  getAmountViewValue(value: number): string | null {
    return this.injector.get(CurrencyPipe).transform(value);
  }

  getRateViewValue(value: number): string | null {
    return this.injector.get(PercentPipe).transform(value / 100, '1.2');
  }
}
