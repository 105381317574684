import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GenericComponent } from '../generic/generic.component';
import { NetsheetService } from '../../services/netsheet.service';
import { has } from 'lodash-es';

@Component({
  selector: 'nsc-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent extends GenericComponent implements OnInit {
  @Input() reportData: any;
  @Output() back = new EventEmitter<void>();
  @Output() export = new EventEmitter<Blob>();

  error: any = '';
  report: any = '';
  subtitles: any = '';
  sections: any = '';
  footer: any = '';
  notes: any = '';
  disclaimers: any = '';
  copyright: any = '';
  exportLoading = false;

  constructor(private netsheetService: NetsheetService) {
    super();
  }

  ngOnInit(): void {
    this.error = '';

    if (has(this.reportData, 'body.report')) {
      this.report = this.reportData.body.report;
      this.subtitles = has(this.report, 'subtitle') ? this.report.subtitle : [];
      this.sections = has(this.report, 'sections') ? this.report.sections : [];
    } else {
      this.report = '';
      this.error = 'Failed to load report. Please try again later!';
    }

    if (has(this.reportData, 'footer')) {
      this.footer = this.reportData.footer;
      this.notes = has(this.footer, 'note') ? this.footer.note : [];
      this.disclaimers = has(this.footer, 'disclaimer') ? this.footer.disclaimer : [];
      this.copyright = has(this.footer, 'copyright') ? this.footer.copyright : '';
    } else {
      this.footer = '';
    }
  }

  onBack(): void {
    this.back.emit();
  }

  onExport(): void {
    this.exportLoading = true;

    this.subscriptions.push(
      this.netsheetService.download(this.reportData).subscribe({
        next: (response: Blob) => {
          this.export.emit(response);
          this.exportLoading = false;
        },
        error: () => {
          this.error = 'Failed to download pdf. Please try again later!';
          this.exportLoading = false;
        }
      })
    );
  }
}
