import { Component, OnChanges, OnInit, SimpleChanges, Input } from '@angular/core';
import { FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import { QuestionnaireConfigService } from '../../services/questionnaire-config.service';
import { GenericComponent } from '../generic/generic.component';

@Component({
  selector: 'nsc-customer-information',
  templateUrl: './customer-information.component.html',
  styleUrls: ['./customer-information.component.scss']
})
export class CustomerInformationComponent extends GenericComponent implements OnInit, OnChanges {
  @Input() form!: FormGroup<{
    transactionInformation: UntypedFormGroup;
    customerInformation: UntypedFormGroup;
  }>;

  mainConfig: any = {};
  config: any = {
    partyFirstName: { visible: true },
    partyLastName: { visible: true },
    partyType: { visible: true }
  };

  get customerInformation() {
    return this.form?.controls['customerInformation'];
  }

  get transactionInformation() {
    return this.form?.controls['transactionInformation'];
  }

  constructor(private questionnaireConfigService: QuestionnaireConfigService) {
    super();
  }

  ngOnInit(): void {
    const state = this.transactionInformation?.get('propertyState')?.value;

    this.addUniqueSubscription('load-config', this.getConfigLoadSubscription(state));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.form && this.form) {
      this.addUniqueSubscription(
        'property-state-change',
        this.transactionInformation?.get('propertyState')?.valueChanges.subscribe((state: string) => {
          this.addUniqueSubscription('load-config', this.getConfigLoadSubscription(state));
        })
      );
    }
  }

  getConfigLoadSubscription(state: string): Subscription {
    return this.questionnaireConfigService.getConfigByState(state).subscribe((response) => {
      this.mainConfig = response;
      this.onConfigUpdate();
    });
  }

  onConfigUpdate(): void {
    for (const field of Object.keys(this.config)) {
      this.config[field] = this.mainConfig[field];

      if (this.config[field].required) {
        this.customerInformation?.get(field)?.setValidators([Validators.required]);
      } else {
        this.customerInformation?.get(field)?.clearValidators();
      }
    }

    this.customerInformation?.updateValueAndValidity({ emitEvent: false });
  }
}
