import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import { DeviceService } from '../../services/device.service';
import { QuestionnaireConfigService } from '../../services/questionnaire-config.service';
import { GenericComponent } from '../generic/generic.component';

import moment from 'moment';

@Component({
  selector: 'nsc-taxes',
  templateUrl: './taxes.component.html',
  styleUrls: ['./taxes.component.scss']
})
export class TaxesComponent extends GenericComponent implements OnInit, OnChanges {
  @Input() form!: FormGroup<{
    transactionInformation: UntypedFormGroup;
    customerInformation: UntypedFormGroup;
    taxes: UntypedFormGroup;
  }>;

  mainConfig: any = {};
  config: any = {
    homeownersDuesAmount: { visible: true },
    homeownersDuesDueDate: { visible: true },
    homeownersDuesPaid: { visible: true },
    homeownersDuesTerm: { visible: true },
    taxes: { visible: true },
    taxesNextInstallment: { visible: true },
    taxesPaid: { visible: true },
    taxesPaidToDateDropdown: { visible: true },
    taxesPaidToDatePicker: { visible: true },
    taxesTerm: { visible: true }
  };

  isMobile = false;
  hideComponent = false;

  availableTaxesPaidToDates: string[] = [];
  availableTaxesTerms: string[] = ['Quarterly', 'Semi-Annually', 'Annually'];
  availableHomeownersDuesTerms: string[] = ['Monthly', 'Quarterly', 'Semi-Annually', 'Annually'];

  get customerInformation() {
    return this.form?.controls['customerInformation'];
  }

  get transactionInformation() {
    return this.form?.controls['transactionInformation'];
  }

  get taxes() {
    return this.form?.controls['taxes'];
  }

  constructor(
    private deviceService: DeviceService,
    private questionnaireConfigService: QuestionnaireConfigService
  ) {
    super();
  }

  ngOnInit(): void {
    const state = this.transactionInformation?.get('propertyState')?.value;

    this.subscriptions.push(
      this.deviceService.isMobile().subscribe((isMobile: boolean) => {
        this.isMobile = isMobile;
      })
    );

    this.addUniqueSubscription('load-config', this.getConfigLoadSubscription(state));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.form && this.form) {
      this.addUniqueSubscription(
        'taxes-property-state-change',
        this.transactionInformation?.get('propertyState')?.valueChanges.subscribe((state: string) => {
          this.addUniqueSubscription('load-config', this.getConfigLoadSubscription(state));
        })
      );

      this.addUniqueSubscription(
        'taxes-party-type-change',
        this.customerInformation?.get('partyType')?.valueChanges.subscribe(() => {
          this.updateAllCascade();
        })
      );

      this.addUniqueSubscription(
        'taxes-estcoe-change',
        this.transactionInformation?.get('attEstcoe')?.valueChanges.subscribe(() => {
          this.updateConfigByEstcoe();
          this.onConfigUpdate(false);
        })
      );
    }
  }

  getConfigLoadSubscription(state: string): Subscription {
    return this.questionnaireConfigService.getConfigByState(state).subscribe((response) => {
      this.mainConfig = response;

      this.onConfigUpdate(true);
      this.updateAllCascade();
    });
  }

  updateAllCascade(): void {
    this.updateConfigByPartyType();
    this.updateConfigByEstcoe();
    this.updateAvailableTaxesPaidToDatesByState();
    this.onConfigUpdate(false);
  }

  updateConfigByPartyType(): void {
    this.hideComponent = this.customerInformation?.get('partyType')?.value === 'L';
  }

  updateConfigByEstcoe(): void {
    const state = this.transactionInformation?.get('propertyState')?.value;
    const estcoe = this.transactionInformation?.get('attEstcoe')?.value;

    if (estcoe) {
      const selectedMonth = moment(estcoe).get('M');

      if (state === 'OK') {
        if (selectedMonth === 10 || selectedMonth === 11) {
          this.config.taxesPaid = {
            visible: true,
            required: this.mainConfig.taxesPaid.required
          };
        } else {
          this.config.taxesPaid = {
            visible: false,
            required: false
          };
        }
      }

      if (state === 'TX') {
        if (selectedMonth === 0) {
          this.config.homeownersDuesPaid = {
            visible: true,
            required: this.mainConfig.homeownersDuesPaid.required
          };

          this.config.taxesPaid = {
            visible: false,
            required: false
          };
        } else if (selectedMonth === 11) {
          this.config.homeownersDuesPaid = {
            visible: false,
            required: false
          };

          this.config.taxesPaid = {
            visible: true,
            required: this.mainConfig.taxesPaid.required
          };
        } else {
          this.config.homeownersDuesPaid = {
            visible: false,
            required: false
          };

          this.config.taxesPaid = {
            visible: false,
            required: false
          };
        }
      }
    } else {
      if (state === 'OK') {
        this.config.taxesPaid = {
          visible: false,
          required: false
        };
      }

      if (state === 'TX') {
        this.config.homeownersDuesPaid = {
          visible: false,
          required: false
        };

        this.config.taxesPaid = {
          visible: false,
          required: false
        };
      }
    }
  }

  updateAvailableTaxesPaidToDatesByState(): void {
    const state = this.transactionInformation?.get('propertyState')?.value;

    if (state === 'CA' || state === 'OH') {
      const currentDate = moment();

      if (currentDate.get('month') < 6) {
        this.availableTaxesPaidToDates = [
          currentDate.clone().set('M', 0).set('D', 1).format('MM/DD/YYYY'),
          currentDate.clone().set('M', 6).set('D', 1).format('MM/DD/YYYY')
        ];
      } else {
        this.availableTaxesPaidToDates = [
          currentDate.clone().set('M', 6).set('D', 1).format('MM/DD/YYYY'),
          currentDate.clone().add(1, 'year').set('M', 0).set('D', 1).format('MM/DD/YYYY')
        ];
      }
    } else {
      this.availableTaxesPaidToDates = [];
    }
  }

  onConfigUpdate(isMainConfigUpdated = false): void {
    for (const field of Object.keys(this.config)) {
      if (isMainConfigUpdated) {
        this.config[field] = this.mainConfig[field];
      }

      if (this.config[field].required) {
        this.taxes?.get(field)?.setValidators([Validators.required]);
      } else {
        this.taxes?.get(field)?.clearValidators();
      }
    }

    this.taxes?.updateValueAndValidity({ emitEvent: false });
  }
}
