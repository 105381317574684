import { Component, OnChanges, OnInit, SimpleChanges, Input } from '@angular/core';
import { FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import { DeviceService } from '../../services/device.service';
import { QuestionnaireConfigService } from '../../services/questionnaire-config.service';
import { GenericComponent } from '../generic/generic.component';

import moment from 'moment';

@Component({
  selector: 'nsc-mortgages',
  templateUrl: './mortgages.component.html',
  styleUrls: ['./mortgages.component.scss']
})
export class MortgagesComponent extends GenericComponent implements OnInit, OnChanges {
  @Input() form!: FormGroup<{
    transactionInformation: UntypedFormGroup;
    customerInformation: UntypedFormGroup;
    mortgages: UntypedFormGroup;
  }>;

  mainConfig: any = {};
  config: any = {
    mortgagePrimaryBalance: { visible: true },
    mortgagePrimaryInterestRate: { visible: true },
    mortgagePrimaryLienCloseDate: { visible: true },
    mortgagePrimaryPrepayPenalty: { visible: true },
    mortgageSecondaryBalance: { visible: true },
    mortgageSecondaryInterestRate: { visible: true },
    mortgageSecondaryPrepayPenalty: { visible: true }
  };

  isMobile = false;
  hideComponent = false;
  maxDate = moment().subtract(1, 'day');

  get customerInformation() {
    return this.form?.controls['customerInformation'];
  }

  get mortgages() {
    return this.form?.controls['mortgages'];
  }

  get transactionInformation() {
    return this.form?.controls['transactionInformation'];
  }

  constructor(
    private deviceService: DeviceService,
    private questionnaireConfigService: QuestionnaireConfigService
  ) {
    super();
  }

  ngOnInit(): void {
    const state = this.transactionInformation?.get('propertyState')?.value;
    this.subscriptions.push(
      this.deviceService.isMobile().subscribe((isMobile: boolean) => {
        this.isMobile = isMobile;
      })
    );

    this.addUniqueSubscription('load-config', this.getConfigLoadSubscription(state));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.form && this.form) {
      this.addUniqueSubscription(
        'property-state-change',
        this.transactionInformation?.get('propertyState')?.valueChanges.subscribe((state: string) => {
          this.addUniqueSubscription('load-config', this.getConfigLoadSubscription(state));
        })
      );

      this.addUniqueSubscription(
        'party-type-change',
        this.customerInformation?.get('partyType')?.valueChanges.subscribe(() => {
          this.updateConfigByPartyType();
        })
      );

      this.addUniqueSubscription(
        'loan-primary-amount-change',
        this.transactionInformation?.get('loanPrimaryAmount')?.valueChanges.subscribe(() => {
          this.updateConfigByLoanPrimaryAmount();
        })
      );
    }
  }

  getConfigLoadSubscription(state: string): Subscription {
    return this.questionnaireConfigService.getConfigByState(state).subscribe((response) => {
      this.mainConfig = response;

      this.onConfigUpdate(true);
      this.updateAllCascade();
    });
  }

  updateAllCascade(): void {
    this.updateConfigByPartyType();
    this.updateConfigByLoanPrimaryAmount();
    this.onConfigUpdate(false);
  }

  updateConfigByPartyType(): void {
    this.hideComponent = this.customerInformation?.get('partyType')?.value === 'B';
  }

  updateConfigByLoanPrimaryAmount(): void {
    const state = this.transactionInformation?.get('propertyState')?.value;
    const loanPrimaryAmount = this.transactionInformation?.get('loanPrimaryAmount')?.value;

    if (state === 'TX') {
      if (loanPrimaryAmount) {
        this.config.mortgagePrimaryLienCloseDate = {
          visible: true,
          required: this.mainConfig.mortgagePrimaryLienCloseDate.required
        };
      } else {
        this.config.mortgagePrimaryLienCloseDate = {
          visible: false,
          required: false
        };
      }
    }
  }

  onConfigUpdate(isMainConfigUpdated = false): void {
    for (const field of Object.keys(this.config)) {
      if (isMainConfigUpdated) {
        this.config[field] = this.mainConfig[field];
      }

      if (this.config[field].required) {
        this.mortgages?.get(field)?.setValidators([Validators.required]);
      } else {
        this.mortgages?.get(field)?.clearValidators();
      }
    }

    this.mortgages?.updateValueAndValidity({ emitEvent: false });
  }
}
