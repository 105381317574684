<div [formGroup]="form">
  <div class="tw-grid" formGroupName="transactionInformation">
    <mat-form-field [hidden]="!config.attSalesPrice.visible" class="tw-mb-1">
      <mat-label>Sales Price</mat-label>
      <input
        matInput
        formControlName="attSalesPrice"
        nscRateAmount="amount"
        type="text"
        inputmode="decimal"
        placeholder="$"
        autocomplete="off"
        [required]="config.attSalesPrice.required"
      />
      <mat-error *ngIf="transactionInformation?.controls?.attSalesPrice?.errors">
        <nsc-validation-error class="tw-block" [errors]="transactionInformation?.controls?.attSalesPrice?.errors">
        </nsc-validation-error>
      </mat-error>
    </mat-form-field>

    <mat-form-field [hidden]="!config.attEstcoe.visible" class="tw-mb-1">
      <mat-label>Closing Date</mat-label>
      <input
        matInput
        readonly
        formControlName="attEstcoe"
        autocomplete="off"
        (click)="attEstcoe.open()"
        [matDatepicker]="attEstcoe"
        [min]="minDate"
        [required]="config.attEstcoe.required"
      />
      <mat-datepicker #attEstcoe [touchUi]="isMobile">
        <mat-datepicker-actions>
          <button mat-button matDatepickerCancel>Cancel</button>
          <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
        </mat-datepicker-actions>
      </mat-datepicker>
      <mat-error *ngIf="transactionInformation?.controls?.attEstcoe?.errors">
        <nsc-validation-error class="tw-block" [errors]="transactionInformation?.controls?.attEstcoe?.errors">
        </nsc-validation-error>
      </mat-error>
    </mat-form-field>

    <mat-form-field [hidden]="!config.loanPrimaryAmount.visible" class="tw-mb-1">
      <mat-label>Loan Amount</mat-label>
      <input
        matInput
        formControlName="loanPrimaryAmount"
        nscRateAmount="amount"
        type="text"
        inputmode="decimal"
        placeholder="$"
        autocomplete="off"
        [required]="config.loanPrimaryAmount.required"
      />
      <mat-error *ngIf="transactionInformation?.controls?.loanPrimaryAmount?.errors">
        <nsc-validation-error class="tw-block" [errors]="transactionInformation?.controls?.loanPrimaryAmount?.errors">
        </nsc-validation-error>
      </mat-error>
    </mat-form-field>

    <mat-form-field [hidden]="!config.loanPrimaryOriginationFees.visible" class="tw-mb-1">
      <mat-label>Loan Origination Fees</mat-label>
      <input
        matInput
        formControlName="loanPrimaryOriginationFees"
        nscRateAmount="rateAmount"
        type="text"
        inputmode="text"
        placeholder="(percentage or amount)"
        autocomplete="off"
        [required]="config.loanPrimaryOriginationFees.required"
      />
      <mat-error *ngIf="transactionInformation?.controls?.loanPrimaryOriginationFees?.errors">
        <nsc-validation-error
          class="tw-block"
          [errors]="transactionInformation?.controls?.loanPrimaryOriginationFees?.errors"
        >
        </nsc-validation-error>
      </mat-error>
    </mat-form-field>

    <mat-form-field [hidden]="!config.loanPrimaryInterestRate.visible" class="tw-mb-1">
      <mat-label>Interest % On New Loan</mat-label>
      <input
        matInput
        formControlName="loanPrimaryInterestRate"
        nscRateAmount="rate"
        type="text"
        inputmode="decimal"
        placeholder="%"
        autocomplete="off"
        [required]="config.loanPrimaryInterestRate.required"
      />
      <mat-error *ngIf="transactionInformation?.controls?.loanPrimaryInterestRate?.errors">
        <nsc-validation-error
          class="tw-block"
          [errors]="transactionInformation?.controls?.loanPrimaryInterestRate?.errors"
        >
        </nsc-validation-error>
      </mat-error>
    </mat-form-field>

    <mat-form-field [hidden]="!config.buyerCreditAmount.visible" class="tw-mb-1">
      <mat-label>{{ buyerCreditAmountLabel }}</mat-label>
      <input
        matInput
        formControlName="buyerCreditAmount"
        nscRateAmount="amount"
        type="text"
        inputmode="decimal"
        placeholder="$"
        autocomplete="off"
        [required]="config.buyerCreditAmount.required"
      />
      <mat-error *ngIf="transactionInformation?.controls?.buyerCreditAmount?.errors">
        <nsc-validation-error class="tw-block" [errors]="transactionInformation?.controls?.buyerCreditAmount?.errors">
        </nsc-validation-error>
      </mat-error>
    </mat-form-field>

    <mat-form-field [hidden]="!config.warrantyPremiumAmount.visible" class="tw-mb-1">
      <mat-label>Home Warranty Premium</mat-label>
      <input
        matInput
        formControlName="warrantyPremiumAmount"
        nscRateAmount="amount"
        type="text"
        inputmode="decimal"
        placeholder="$"
        autocomplete="off"
        [required]="config.warrantyPremiumAmount.required"
      />
      <mat-error *ngIf="transactionInformation?.controls?.warrantyPremiumAmount?.errors">
        <nsc-validation-error
          class="tw-block"
          [errors]="transactionInformation?.controls?.warrantyPremiumAmount?.errors"
        >
        </nsc-validation-error>
      </mat-error>
    </mat-form-field>

    <mat-form-field [hidden]="!config.hazardInsuranceAmount.visible" class="tw-mb-1">
      <mat-label>Hazard Insurance Premium</mat-label>
      <input
        matInput
        formControlName="hazardInsuranceAmount"
        nscRateAmount="amount"
        type="text"
        inputmode="decimal"
        placeholder="$"
        autocomplete="off"
        [required]="config.hazardInsuranceAmount.required"
      />
      <mat-error *ngIf="transactionInformation?.controls?.hazardInsuranceAmount?.errors">
        <nsc-validation-error
          class="tw-block"
          [errors]="transactionInformation?.controls?.hazardInsuranceAmount?.errors"
        >
        </nsc-validation-error>
      </mat-error>
    </mat-form-field>

    <mat-form-field [hidden]="!config.mortgagePayoffCount.visible" class="tw-mb-1">
      <mat-label>Number of Mortgage Payoffs</mat-label>
      <input
        matInput
        formControlName="mortgagePayoffCount"
        type="number"
        inputmode="numeric"
        placeholder="Number of Mortgage Payoffs"
        autocomplete="off"
        [required]="config.mortgagePayoffCount.required"
      />
      <mat-error *ngIf="transactionInformation?.controls?.mortgagePayoffCount?.errors">
        <nsc-validation-error class="tw-block" [errors]="transactionInformation?.controls?.mortgagePayoffCount?.errors">
        </nsc-validation-error>
      </mat-error>
    </mat-form-field>

    <mat-form-field [hidden]="!config.attWaterBill.visible" class="tw-mb-1">
      <mat-label>Final Water Bill</mat-label>
      <input
        matInput
        formControlName="attWaterBill"
        nscRateAmount="amount"
        type="text"
        inputmode="decimal"
        placeholder="$"
        autocomplete="off"
        [required]="config.attWaterBill.required"
      />
      <mat-error *ngIf="transactionInformation?.controls?.attWaterBill?.errors">
        <nsc-validation-error class="tw-block" [errors]="transactionInformation?.controls?.attWaterBill?.errors">
        </nsc-validation-error>
      </mat-error>
    </mat-form-field>

    <mat-form-field [hidden]="!config.attSewerBill.visible" class="tw-mb-1">
      <mat-label>Final Sewer Bill</mat-label>
      <input
        matInput
        formControlName="attSewerBill"
        nscRateAmount="amount"
        type="text"
        inputmode="decimal"
        placeholder="$"
        autocomplete="off"
        [required]="config.attSewerBill.required"
      />
      <mat-error *ngIf="transactionInformation?.controls?.attSewerBill?.errors">
        <nsc-validation-error class="tw-block" [errors]="transactionInformation?.controls?.attSewerBill?.errors">
        </nsc-validation-error>
      </mat-error>
    </mat-form-field>

    <mat-form-field [hidden]="!config.attBrokerageFee.visible" class="tw-mb-1">
      <mat-label>Brokerage Fee</mat-label>
      <input
        matInput
        formControlName="attBrokerageFee"
        nscRateAmount="amount"
        type="text"
        inputmode="decimal"
        placeholder="$"
        autocomplete="off"
        [required]="config.attBrokerageFee.required"
      />
      <mat-error *ngIf="transactionInformation?.controls?.attBrokerageFee?.errors">
        <nsc-validation-error class="tw-block" [errors]="transactionInformation?.controls?.attBrokerageFee?.errors">
        </nsc-validation-error>
      </mat-error>
    </mat-form-field>
  </div>
</div>
