import { Validators } from '@angular/forms';

export function updateConfigByMainConfig(config: any, mainConfig: any, updates: any) {
  for (const item of updates) {
    if (mainConfig[item.key].visible) {
      if (item.visible) {
        config[item.key] = { visible: item.visible, required: mainConfig[item.key].required };
      } else {
        config[item.key] = { visible: item.visible, required: false };
      }
    }
  }
}

export function onConfigUpdate(config: any, mainConfig: any, transactionInformation: any, isMainConfigUpdated = false) {
  for (const field of Object.keys(config)) {
    if (isMainConfigUpdated) {
      config[field] = mainConfig[field];
    }
    if (config[field].required) {
      transactionInformation?.get(field)?.setValidators([Validators.required]);
    } else {
      transactionInformation?.get(field)?.clearValidators();
    }
  }
  transactionInformation?.updateValueAndValidity({ emitEvent: false });
}
