import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { GenericComponent } from '../generic/generic.component';

@Component({
  selector: 'nsc-transaction-information',
  templateUrl: './transaction-information.component.html',
  styleUrls: ['./transaction-information.component.scss']
})
export class TransactionInformationComponent extends GenericComponent {
  @Input() form!: UntypedFormGroup;

  constructor() {
    super();
  }
}
