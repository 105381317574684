import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';
import { BranchModel } from '../models/branch.model';
import { CityModel } from '../models/city.model';
import { CountyModel } from '../models/county.model';
import { FipsModel } from '../models/fips.model';
import { InfoModel } from '../models/info.model';
import { StateModel } from '../models/state.model';
import { MiscModel } from '../models/misc.model';
import { NetsheetCalculatorModel } from '../models/netsheet-calculator.model';

import { isBoolean, toString } from 'lodash-es';

import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class NetsheetService {
  constructor(private apiService: ApiService) {}

  getInformation(): Observable<InfoModel> {
    return this.apiService.get('/information');
  }

  getStateAndCountyByFips(fips: string): Observable<FipsModel> {
    return this.apiService.get('/location/fips/' + fips);
  }

  getStates(): Observable<StateModel[]> {
    return this.apiService.get('/location/states');
  }

  getCountiesByState(state: string, isEscrow?: boolean): Observable<CountyModel[]> {
    let params = new HttpParams();

    if (isEscrow) {
      params = params.append('isEscrow', isEscrow.toString());
    }

    return this.apiService.get('/location/states/' + state + '/counties', { params });
  }

  getCitiesByStateAndCounty(state: string, county: string, isEscrow?: boolean): Observable<CityModel[]> {
    let params = new HttpParams();

    if (isEscrow) {
      params = params.append('isEscrow', isEscrow.toString());
    }

    return this.apiService.get('/location/states/' + state + '/counties/' + county + '/cities', { params });
  }

  getBranchesByStateAndCounty(state: string, county: string, isEscrow?: boolean): Observable<BranchModel[]> {
    let params = new HttpParams();

    if (isEscrow) {
      params = params.append('isEscrow', isEscrow.toString());
    }

    return this.apiService.get('/location/states/' + state + '/counties/' + county + '/branches', { params });
  }

  getZipsByStateAndCountyAndCity(state: string, county: string, city: string): Observable<string[]> {
    return this.apiService.get('/location/states/' + state + '/counties/' + county + '/cities/' + city + '/zips');
  }

  calculate(data: any): Observable<any> {
    return this.apiService.post('/netsheet', this.createRequestData(data));
  }

  download(reportData: any): Observable<any> {
    return this.apiService.downloadReport(reportData);
  }

  private createRequestData(data: any): NetsheetCalculatorModel {
    let requestData: NetsheetCalculatorModel;

    requestData = this.processTransactionFormData(data);
    requestData = this.processMortgagesFormData(data, requestData);
    requestData = this.processSalesCommissionFormData(data, requestData);
    requestData = this.processTaxesFormData(data, requestData);
    requestData = this.processMiscItemsFormDataData(data, requestData);
    requestData = this.processUserInformationFormData(data, requestData);

    return requestData;
  }

  private processTransactionFormData(data: any): NetsheetCalculatorModel {
    let requestData: NetsheetCalculatorModel = {
      attEstcoe: data.attEstcoe,
      escrowState: data.escrowState,
      partyFirstName: data.partyFirstName,
      partyLastName: data.partyLastName,
      partyType: data.partyType,
      propertyCounty: data.propertyCounty,
      propertyState: data.propertyState
    };

    if (data.propertyAddress) {
      requestData.propertyAddress = data.propertyAddress;
    }

    if (!['OH'].includes(data.propertyState)) {
      requestData.propertyCity = data.propertyCity;

      if (data.propertyZip) {
        requestData.propertyZip = data.propertyZip;
      }
    }

    if (!['OK', 'OR'].includes(data.propertyState)) {
      if (data.escrowCounty) {
        requestData.escrowCounty = data.escrowCounty;
      }
    }

    if (data.propertyState === 'CA') {
      if (isBoolean(data.attSubEscrow)) {
        if (
          [
            'Imperial',
            'Kern',
            'Los Angeles',
            'Orange',
            'Riverside',
            'San Bernardino',
            'San Diego',
            'San Luis Obispo',
            'Santa Barbara',
            'Ventura'
          ].includes(data.escrowCounty)
        ) {
          requestData.attSubEscrow = data.attSubEscrow;
        }
      }
    }

    if (data.propertyState === 'TX') {
      if (data.escrowBranch) {
        requestData.escrowBranch = data.escrowBranch;
      }

      if (data.attOwnersPolicySplit) {
        if (data.partyType === 'S') {
          requestData.attOwnersPolicySplit = data.attOwnersPolicySplit === 'Buyer' ? 0 : 100;
        } else if (data.partyType === 'B') {
          requestData.attOwnersPolicySplit = data.attOwnersPolicySplit === 'Buyer' ? 100 : 0;
        }
      }
    }

    if (data.propertyState === 'WA') {
      if (data.escrowCity) {
        requestData.escrowCity = data.escrowCity;
      }
    }

    if (data.partyType === 'S') {
      if (data.warrantyPremiumAmount) {
        requestData.warrantyPremiumAmount = data.warrantyPremiumAmount;
      }

      if (isBoolean(data.attFirpta)) {
        requestData.attFirpta = !data.attFirpta;
      }

      if (isBoolean(data.attPersonalResidence)) {
        requestData.attPersonalResidence = data.attPersonalResidence;
      }

      if (data.propertyState === 'CA') {
        if (isBoolean(data.attFtb)) {
          requestData.attFtb = !data.attFtb;
        }

        if (data.propertyCity === 'Riverside') {
          if (isBoolean(data.propertyIncorporatedArea)) {
            requestData.propertyIncorporatedArea = data.propertyIncorporatedArea;
          }
        }
      }

      if (data.propertyState === 'HI') {
        if (isBoolean(data.attHarpta)) {
          requestData.attHarpta = !data.attHarpta;
        }
      }

      if (data.propertyState === 'OH') {
        if (data.attSewerBill) {
          requestData.attSewerBill = data.attSewerBill;
        }

        if (data.attWaterBill) {
          requestData.attWaterBill = data.attWaterBill;
        }
      }

      if (data.propertyState === 'TX') {
        if (isBoolean(data.attSurveyAmendmentSplit)) {
          requestData.attSurveyAmendmentSplit = data.attSurveyAmendmentSplit ? 100 : 0;
        }
      }

      if (data.escrowState === 'CA' && data.escrowCounty === 'Stanislaus') {
        if (isBoolean(data.attNewLoan)) {
          requestData.attNewLoan = data.attNewLoan;
        }
      }
    }

    if (data.partyType === 'B') {
      if (data.hazardInsuranceAmount) {
        requestData.hazardInsuranceAmount = data.hazardInsuranceAmount;
      }
    }

    if (data.partyType === 'S' || data.partyType === 'B') {
      if (isBoolean(data.attOwnersExemption)) {
        requestData.attOwnersExemption = data.attOwnersExemption;
      }

      if (isBoolean(data.attReo)) {
        requestData.attReo = data.attReo;
      }

      if (isBoolean(data.attShortSale)) {
        requestData.attShortSale = data.attShortSale;
      }

      if (data.attSalesPrice) {
        requestData.attSalesPrice = data.attSalesPrice;
      }

      if (data.buyerCreditAmount) {
        requestData.buyerCreditAmount = data.buyerCreditAmount;
      }

      if (data.propertyState === 'OH') {
        if (data.attBrokerageFee) {
          requestData.attBrokerageFee = data.attBrokerageFee;
        }
      }
    }

    if (data.partyType === 'B' || data.partyType === 'L') {
      if (data.loanPrimaryAmount) {
        requestData.loanPrimaryAmount = data.loanPrimaryAmount;

        if (data.loanPrimaryInterestRate) {
          requestData.loanPrimaryInterestRate = data.loanPrimaryInterestRate;
        }

        if (data.loanPrimaryOriginationFees) {
          requestData = this.mapItemToAmountOrPercentage(
            data,
            requestData,
            'loanPrimaryOriginationFees',
            'loanPrimaryOriginationFees',
            'loanPrimaryOriginationFeesPercent'
          );
        }
      }
    }

    return requestData;
  }

  private processMortgagesFormData(data: any, requestData: NetsheetCalculatorModel): NetsheetCalculatorModel {
    if (data.partyType === 'S' || data.partyType === 'L') {
      if (data.mortgagePayoffCount) {
        requestData.mortgagePayoffCount = data.mortgagePayoffCount;
      }

      if (data.mortgagePrimaryBalance) {
        requestData.mortgagePrimaryBalance = data.mortgagePrimaryBalance;

        if (data.mortgagePrimaryInterestRate) {
          requestData.mortgagePrimaryInterestRate = data.mortgagePrimaryInterestRate;
        }

        if (data.mortgagePrimaryPrepayPenalty) {
          requestData.mortgagePrimaryPrepayPenalty = data.mortgagePrimaryPrepayPenalty;
        }

        if (data.propertyState === 'TX' && data.loanPrimaryAmount) {
          if (data.mortgagePrimaryLienCloseDate) {
            requestData.mortgagePrimaryLienCloseDate = data.mortgagePrimaryLienCloseDate;
          }
        }

        if (data.mortgageSecondaryBalance) {
          requestData.mortgageSecondaryBalance = data.mortgageSecondaryBalance;

          if (data.mortgageSecondaryInterestRate) {
            requestData.mortgageSecondaryInterestRate = data.mortgageSecondaryInterestRate;
          }

          if (data.mortgageSecondaryPrepayPenalty) {
            requestData.mortgageSecondaryPrepayPenalty = data.mortgageSecondaryPrepayPenalty;
          }
        }
      } else if (data.mortgageSecondaryBalance) {
        requestData.mortgagePrimaryBalance = data.mortgageSecondaryBalance;

        if (data.mortgageSecondaryInterestRate) {
          requestData.mortgagePrimaryBalance = data.mortgageSecondaryInterestRate;
        }

        if (data.mortgageSecondaryPrepayPenalty) {
          requestData.mortgagePrimaryBalance = data.mortgageSecondaryPrepayPenalty;
        }
      }
    }

    return requestData;
  }

  private processSalesCommissionFormData(data: any, requestData: NetsheetCalculatorModel): NetsheetCalculatorModel {
    if (data.partyType === 'S') {
      if (data.brokerCommissionTotal) {
        if (data.propertyState === 'OH') {
          requestData.brokerCommissionTotalRate = data.brokerCommissionTotal;

          if (data.brokerCommissionTierRate) {
            requestData.brokerCommissionTierRate = data.brokerCommissionTierRate;
          }
        } else {
          requestData = this.mapItemToAmountOrPercentage(
            data,
            requestData,
            'brokerCommissionTotal',
            'brokerCommissionTotalAmount',
            'brokerCommissionTotalRate'
          );
        }

        if (
          data.propertyState === 'HI' &&
          !data.brokerCommissionSplits &&
          data.brokerCommissionTaxTotal !== undefined
        ) {
          requestData.brokerCommissionTaxTotal = data.brokerCommissionTaxTotal;
        }
      }

      if (data.brokerCommissionSplits) {
        if (data.brokerCommissionListing) {
          if (this.isRate(data.brokerCommissionListing)) {
            requestData.brokerCommissionListingRate = toString(data.brokerCommissionListing).includes('%')
              ? parseFloat(toString(data.brokerCommissionListing).slice(0, -1))
              : parseFloat(toString(data.brokerCommissionListing));
          } else {
            requestData = this.mapItemToAmountOrPercentage(
              data,
              requestData,
              'brokerCommissionListing',
              'brokerCommissionListingAmount',
              'brokerCommissionListingRate'
            );
          }
        }

        if (data.brokerCommissionSelling) {
          if (this.isRate(data.brokerCommissionSelling)) {
            requestData.brokerCommissionSellingRate = toString(data.brokerCommissionSelling).includes('%')
              ? parseFloat(toString(data.brokerCommissionSelling).slice(0, -1))
              : parseFloat(toString(data.brokerCommissionSelling));
          } else {
            requestData = this.mapItemToAmountOrPercentage(
              data,
              requestData,
              'brokerCommissionSelling',
              'brokerCommissionSellingAmount',
              'brokerCommissionSellingRate'
            );
          }
        }

        if (data.propertyState === 'HI') {
          if (data.brokerCommissionTaxListing !== undefined) {
            requestData.brokerCommissionTaxListing = data.brokerCommissionTaxListing;
          }

          if (data.brokerCommissionTaxSelling !== undefined) {
            requestData.brokerCommissionTaxSelling = data.brokerCommissionTaxSelling;
          }
        }
      }
    }

    return requestData;
  }

  private processTaxesFormData(data: any, requestData: NetsheetCalculatorModel): NetsheetCalculatorModel {
    if (data.partyType === 'S' || data.partyType === 'B') {
      if (data.homeownersDuesAmount) {
        requestData.homeownersDuesAmount = data.homeownersDuesAmount;

        if (data.propertyState === 'TX') {
          requestData.homeownersDuesTerm = 'Annually';
          requestData.homeownersDuesPaid = moment(data.attEstcoe).get('M') === 0 ? data.homeownersDuesPaid : true;
        } else {
          if (data.homeownersDuesDueDate) {
            requestData.homeownersDuesDueDate = data.homeownersDuesDueDate;
          }

          if (data.homeownersDuesTerm) {
            requestData.homeownersDuesTerm = data.homeownersDuesTerm;
          }
        }
      }

      if (data.taxes) {
        requestData = this.mapItemToAmountOrPercentage(data, requestData, 'taxes', 'taxesAmount', 'taxesPercent');

        if (data.taxesTerm) {
          if (['CA', 'OH'].includes(data.propertyState)) {
            requestData.taxesTerm = 'Annually';
          }

          if (['AZ', 'HI', 'NV', 'WA'].includes(data.propertyState)) {
            requestData.taxesTerm = data.taxesTerm;
          }
        }

        if (data.taxesPaidToDatePicker && ['AZ', 'HI', 'NV', 'WA'].includes(data.propertyState)) {
          requestData.taxesPaidToDate = data.taxesPaidToDatePicker;
        } else if (data.taxesPaidToDateDropdown && ['CA', 'OH'].includes(data.propertyState)) {
          requestData.taxesPaidToDate = moment(data.taxesPaidToDateDropdown, 'MM/DD/YYYY').toDate();
        } else if (data.taxesPaid !== undefined && ['NM', 'OK', 'OR', 'TX', 'UT'].includes(data.propertyState)) {
          if (data.propertyState === 'OK') {
            requestData.taxesPaid =
              moment(data.attEstcoe).get('M') === 10 || moment(data.attEstcoe).get('M') === 11 ? data.taxesPaid : false;
          } else if (data.propertyState === 'TX') {
            requestData.taxesPaid = moment(data.attEstcoe).get('M') === 11 ? data.taxesPaid : false;
          } else {
            requestData.taxesPaid = data.taxesPaid;
          }
        }

        if (data.taxesNextInstallment && ['AZ', 'HI', 'NV', 'WA'].includes(data.propertyState)) {
          requestData.taxesNextInstallment = data.taxesNextInstallment;
        }
      }
    }

    return requestData;
  }

  private processMiscItemsFormDataData(data: any, requestData: NetsheetCalculatorModel): NetsheetCalculatorModel {
    if (data.miscItems && data.miscItems.length) {
      const miscItemsList: MiscModel[] = [];

      for (const miscItem of data.miscItems) {
        if (miscItem.amount && miscItem.description && miscItem.type) {
          miscItemsList.push(miscItem);
        }
      }

      if (miscItemsList.length) {
        requestData.miscItems = miscItemsList;
      }
    }

    return requestData;
  }

  private processUserInformationFormData(data: any, requestData: NetsheetCalculatorModel): NetsheetCalculatorModel {
    const fields = ['userCompany', 'userEmail', 'userFirstName', 'userLastName', 'userPhone'];

    for (const field of fields) {
      if (data[field]) {
        requestData[field] = data[field];
      }
    }

    return requestData;
  }

  private isRate(value: number | string): boolean {
    return toString(value).includes('%') || (parseFloat(toString(value)) > 0 && parseFloat(toString(value)) < 20);
  }

  private mapItemToAmountOrPercentage(
    formData: any,
    requestData: NetsheetCalculatorModel,
    formDataField: string,
    requestDataAmountField: string,
    requestDataPercentageField: string
  ): NetsheetCalculatorModel {
    if (toString(formData[formDataField]).includes('%')) {
      requestData[requestDataPercentageField] = parseFloat(toString(formData[formDataField]).slice(0, -1));
    } else if (formData[formDataField] > 0 && formData[formDataField] < 20) {
      requestData[requestDataPercentageField] = formData[formDataField];
    } else if (formData[formDataField] >= 20) {
      requestData[requestDataAmountField] = formData[formDataField];
    }
    return requestData;
  }
}
