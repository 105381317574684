<mat-card [hidden]="hideComponent" [formGroup]="form">
  <mat-card-content formGroupName="taxes">
    <div class="tw-grid">
      <p class="tw-text-base tw-font-bold tw-mb-4">Property Taxes / HOA Dues</p>
      <hr class="tw-mb-4" />

      <mat-form-field [hidden]="!config.taxes.visible" class="tw-mb-1">
        <mat-label>Property Taxes</mat-label>
        <input
          matInput
          formControlName="taxes"
          nscRateAmount="rateAmount"
          type="text"
          inputmode="text"
          placeholder="(percentage or amount)"
          autocomplete="off"
          [required]="config.taxes.required"
        />
        <mat-error *ngIf="taxes?.controls?.taxes?.errors">
          <nsc-validation-error class="tw-block" [errors]="taxes?.controls?.taxes?.errors"> </nsc-validation-error>
        </mat-error>
      </mat-form-field>

      <mat-form-field [hidden]="!config.taxesTerm.visible" class="tw-mb-1">
        <mat-label>Property Taxes Term</mat-label>
        <mat-select formControlName="taxesTerm" [required]="config.taxesTerm.required">
          <mat-option *ngFor="let tax of availableTaxesTerms" [value]="tax">
            {{ tax }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="taxes?.controls?.taxesTerm?.errors">
          <nsc-validation-error class="tw-block" [errors]="taxes?.controls?.taxesTerm?.errors"> </nsc-validation-error>
        </mat-error>
      </mat-form-field>

      <mat-form-field [hidden]="!config.taxesPaidToDatePicker.visible" class="tw-mb-1">
        <mat-label>Property Taxes Paid To Date</mat-label>
        <input
          matInput
          readonly
          formControlName="taxesPaidToDatePicker"
          autocomplete="off"
          (click)="taxesPaidToDatePicker.open()"
          [matDatepicker]="taxesPaidToDatePicker"
          [required]="config.taxesPaidToDatePicker.required"
        />
        <mat-datepicker #taxesPaidToDatePicker [touchUi]="isMobile">
          <mat-datepicker-actions>
            <button mat-button matDatepickerCancel>Cancel</button>
            <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
          </mat-datepicker-actions>
        </mat-datepicker>
        <mat-error *ngIf="taxes?.controls?.taxesPaidToDatePicker?.errors">
          <nsc-validation-error class="tw-block" [errors]="taxes?.controls?.taxesPaidToDatePicker?.errors">
          </nsc-validation-error>
        </mat-error>
      </mat-form-field>

      <mat-form-field [hidden]="!config.taxesPaidToDateDropdown.visible" class="tw-mb-1">
        <mat-label>Property Taxes Paid To Date</mat-label>
        <mat-select formControlName="taxesPaidToDateDropdown" [required]="config.taxesPaidToDateDropdown.required">
          <mat-option *ngIf="!config.taxesPaidToDateDropdown.required" [value]="">{{ '' }}</mat-option>
          <mat-option *ngFor="let date of availableTaxesPaidToDates" [value]="date">{{ date }}</mat-option>
        </mat-select>
        <mat-error *ngIf="taxes?.controls?.taxesPaidToDateDropdown?.errors">
          <nsc-validation-error class="tw-block" [errors]="taxes?.controls?.taxesPaidToDateDropdown?.errors">
          </nsc-validation-error>
        </mat-error>
      </mat-form-field>

      <mat-radio-group
        [hidden]="!config.taxesPaid.visible"
        formControlName="taxesPaid"
        class="tw-flex tw-flex-col tw-mb-2"
      >
        <mat-label class="tw-h-7 tw-leading-7">Property Tax Status:</mat-label>
        <mat-radio-button class="tw-h-7 tw-leading-7" [value]="true">Paid</mat-radio-button>
        <mat-radio-button class="tw-h-7 tw-leading-7" [value]="false">Unpaid</mat-radio-button>
      </mat-radio-group>

      <mat-form-field [hidden]="!config.taxesNextInstallment.visible" class="tw-mb-1">
        <mat-label>Property Taxes Next Installment</mat-label>
        <input
          matInput
          formControlName="taxesNextInstallment"
          nscRateAmount="amount"
          type="text"
          inputmode="decimal"
          placeholder="$"
          autocomplete="off"
          [required]="config.taxesNextInstallment.required"
        />
        <mat-error *ngIf="taxes?.controls?.taxesNextInstallment?.errors">
          <nsc-validation-error class="tw-block" [errors]="taxes?.controls?.taxesNextInstallment?.errors">
          </nsc-validation-error>
        </mat-error>
      </mat-form-field>

      <mat-form-field [hidden]="!config.homeownersDuesAmount.visible" class="tw-mb-1">
        <mat-label>Homeowners Dues</mat-label>
        <input
          matInput
          formControlName="homeownersDuesAmount"
          nscRateAmount="amount"
          type="text"
          inputmode="decimal"
          placeholder="$"
          autocomplete="off"
          [required]="config.homeownersDuesAmount.required"
        />
        <mat-error *ngIf="taxes?.controls?.homeownersDuesAmount?.errors">
          <nsc-validation-error class="tw-block" [errors]="taxes?.controls?.homeownersDuesAmount?.errors">
          </nsc-validation-error>
        </mat-error>
      </mat-form-field>

      <mat-form-field [hidden]="!config.homeownersDuesDueDate.visible" class="tw-mb-1">
        <mat-label>Homeowners Dues Due Date</mat-label>
        <input
          matInput
          readonly
          formControlName="homeownersDuesDueDate"
          autocomplete="off"
          (click)="homeownersDuesDueDate.open()"
          [matDatepicker]="homeownersDuesDueDate"
          [required]="config.homeownersDuesDueDate.required"
        />
        <mat-datepicker #homeownersDuesDueDate [touchUi]="isMobile">
          <mat-datepicker-actions>
            <button mat-button matDatepickerCancel>Cancel</button>
            <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
          </mat-datepicker-actions>
        </mat-datepicker>
        <mat-error *ngIf="taxes?.controls?.homeownersDuesDueDate?.errors">
          <nsc-validation-error class="tw-block" [errors]="taxes?.controls?.homeownersDuesDueDate?.errors">
          </nsc-validation-error>
        </mat-error>
      </mat-form-field>

      <mat-form-field [hidden]="!config.homeownersDuesTerm.visible" class="tw-mb-1">
        <mat-label>Homeowners Dues Term</mat-label>
        <mat-select formControlName="homeownersDuesTerm" [required]="config.homeownersDuesTerm.required">
          <mat-option *ngFor="let term of availableHomeownersDuesTerms" [value]="term">
            {{ term }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="taxes?.controls?.homeownersDuesTerm?.errors">
          <nsc-validation-error class="tw-block" [errors]="taxes?.controls?.homeownersDuesTerm?.errors">
          </nsc-validation-error>
        </mat-error>
      </mat-form-field>

      <mat-radio-group
        [hidden]="!config.homeownersDuesPaid.visible"
        formControlName="homeownersDuesPaid"
        class="tw-flex tw-flex-col"
      >
        <mat-label class="tw-h-7 tw-leading-7">Homeowners Dues Status:</mat-label>
        <mat-radio-button class="tw-h-7 tw-leading-7" [value]="true">Paid</mat-radio-button>
        <mat-radio-button class="tw-h-7 tw-leading-7" [value]="false">Unpaid</mat-radio-button>
      </mat-radio-group>
    </div>
  </mat-card-content>
</mat-card>
